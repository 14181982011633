import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { forkJoin, from, Observable, of } from "rxjs";
import { catchError, first, map, switchMap } from "rxjs/operators";
import environment from "src/environments/environment";
import {
  ApplicationRoleService,
  ApplicationService,
  BoroughService,
  ContractService,
  INavigationRoot,
  ModuleService,
  NavigationService,
  UserService,
} from "../api-generated";
import { Borough } from "../api-generated/model/borough";
import { TPApplication } from "../api-generated/model/tPApplication";
import { TPApplicationRole } from "../api-generated/model/tPApplicationRole";
import { TPContract } from "../api-generated/model/tPContract";
import { TPModule } from "../api-generated/model/tPModule";
import LoadingService from "../services/loading.service";
import LocalApplicationService from "../services/local-application.service";
import LocalApplicationRoleService from "../services/local-applicationRole.service";
import LocalBoroughService from "../services/local-borough.service";
import LocalContractService from "../services/local-contract.service";
import LocalModuleService from "../services/local-module.service";
import NavService from "../services/nav.service";
import {
  SubmittalDDCCoordinatorId,
  SubmittalExecutiveId,
  globalAdmin,
} from "../shared/staticValue";
import { AuthService } from "@bbj/components";
import LocalUserService from "../services/local-user.service";

@Injectable()
export default class LoginUserResolve {
  constructor(
    private loadingService: LoadingService,
    private authService: AuthService,
    private router: Router,
    private contractService: ContractService,
    private applicationService: ApplicationService,
    private userService: UserService,
    private boroughService: BoroughService,
    private moduleService: ModuleService,
    private applicationRoleService: ApplicationRoleService,
    public localApplicationService: LocalApplicationService,
    public localContractService: LocalContractService,
    public localBoroughService: LocalBoroughService,
    public localModuleService: LocalModuleService,
    public localApplicationRoleService: LocalApplicationRoleService,
    public navService: NavService,
    public navigationService: NavigationService,
  ) {}

  resolve(): Observable<void> {
    this.loadingService.start();

    return from(this.authService.login()).pipe(
      first(),
      switchMap((userId) => {
        if (!userId) {
          return of();
        }
        return this.userService.getUser(userId);
      }),
      switchMap((loginUser) => {
        if (!loginUser) {
          return of();
        }
        const permission = this.authService.getPermission();
        this.navService.setUserItem(loginUser.givenName, loginUser.surname, loginUser.id, loginUser.mail, loginUser.companyName, permission);
        if (!this.navService.getUser().Permissions.includes("Admin")) {
          window.location.href = environment.url.portal;
          return of();
        }
        const boroughs = this.localBoroughService.getItems();
        const contracts = this.localContractService.getItems();
        const modules = this.localModuleService.getItems();
        const applications = this.localApplicationService.getItems();
        const applicationRoles = this.localApplicationRoleService.getItems();
        const boroughNavigation = this.navService.getBroughData();
        const combinedBoroughs = this.navService.getrecentvalue();
        const globalNavigation = this.navService.getGlobalData();
        if (
          !contracts ||
          !applications ||
          !applicationRoles ||
          !boroughs ||
          !modules
        ) {
          return forkJoin([
            this.boroughService.getAllBorough(
              this.navService.getUser().Permissions.includes(globalAdmin)
                ? "admin"
                : "user",
            ),
            this.contractService.getAllContract(),
            this.moduleService.getAllModules(),
            this.applicationService.getAllApplication(),
            this.applicationRoleService.getAllApplicationRole(),
            this.navigationService.getAllMenus(),
          ]);
        }
        return of([
          boroughs,
          contracts,
          modules,
          applications,
          applicationRoles,
          { boroughNavigation, combinedBoroughs, globalNavigation },
        ]);
      }),
      map(
        ([boroughs, contracts, modules, applications, applicationRoles, root]: [
          Borough[],
          TPContract[],
          TPModule[],
          TPApplication[],
          TPApplicationRole[],
          INavigationRoot,
        ]) => {
          this.localBoroughService.setItems(boroughs);
          this.localContractService.setItems(contracts);
          this.localModuleService.setItems(modules);
          this.localApplicationService.setItems(applications);
          this.localApplicationRoleService.setItems(
            applicationRoles
              .filter((r) => {
                return (
                  r.Guid !== SubmittalDDCCoordinatorId &&
                  r.Guid !== SubmittalExecutiveId
                );
              })
              .sort((a, b) => {
                return a.Role > b.Role ? 1 : -1;
              }),
          );
          if (root) {
            this.navService.setBroughData(root.boroughNavigation);
            this.navService.setGlobalData(root.globalNavigation);
            this.navService.setDataSubject(root.combinedBoroughs);

            if (root.globalNavigation.length) {
              this.navService.setSelectedId(
                root.globalNavigation[0].globalModule.Guid,
              );
            } else if (root.boroughNavigation.length) {
              this.navService.setSelectedId(
                root.boroughNavigation[0].boroughItem.Guid,
              );

              this.localContractService.setItem(
                root.boroughNavigation[0].projects[0],
              );
            }
          }
          return;
        },
      ),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        this.router.navigate(["error", error.status ? error.status : 500]);
        return of();
      }),
    );
  }
}
